import { ChannelModel } from "../../../models/ChannelModel";
import { ChannelType } from "../ChannelProvider";

export const BCAVAFlip: ChannelModel = {
  thumbnail: "bca sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bca",
  title: "BCA",
  subtitle: "BCA Virtual Account",
  vendor: "Rumah Yatim",
  image_url: "/assets/bca.png",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Transfer.",
        "Pilih Ke Rek BCA Virtual Account.",
        "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar.",
        "Masukkan jumlah tagihan yang akan anda bayar.",
        "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
      ],
    },
    {
      name: "Klik BCA",
      steps: [
        "Pilih menu Transfer Dana.",
        "Pilih Transfer ke BCA Virtual Account.",
        "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer.",
        "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan.",
        "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit.",
        "Transaksi Anda selesai.",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Lakukan log in pada aplikasi BCA Mobile.",
        "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA.",
        "Pilih m-Transfer > BCA Virtual Account.",
        "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan.",
        "Masukkan jumlah yang ingin dibayarkan.",
        "Masukkan pin m-BCA.",
        "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran.",
      ],
    },
  ],
};

export const BSIVAFlip: ChannelModel = {
  thumbnail: "bsi sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bsm",
  title: "BSI (Bank Syariah Indonesia)",
  subtitle: "BSI Virtual Account",
  image_url: "/assets/lgbsi.png",
  hows: [
    {
      name: "ATM BSI",
      steps: [
        "Pilih Menu Payment/Pembayaran/Pembelian",
        "Pilih Institusi/Akademik/Wakaf",
        "Masukkan kode institusi: 9042 disertai no VA pembayaran Anda Format 9042xxxxxxxxxxxx (9042 = kode institusi, xxxxxxxxxxxx = nomor pembayaran / virtual account) contoh: 9042123456789123",
        "Tekan tombol Benar/Selanjutnya",
        "Kemudian tampil informasi data transaksi anda, pastikan kembali nama tujuan dan jumlah pembayaran sesuai tagihan Anda",
        "Jika data sudah benar pilih BENAR/YA",
        "Akan keluar bukti transaksi jika berhasil",
      ],
    },
    {
      name: "BSI MOBILE",
      steps: [
        "Pilih Menu Payment / Pembayaran",
        "Pilih Institusi/Akademik/Wakaf",
        "Masukkan kode institusi: 9042",
        "Masukkan no VA pembayaran Anda tanpa diikuti kode institusi, lalu klik “setuju“.",
        "Tekan tombol Selanjutnya, Kemudian tampil informasi data transaksi anda, pastikan kembali nama tujuan dan jumlah pembayaran sesuai tagihan Anda, lalu klik Selanjutnya",
        "Masukan PIN",
        "Tekan tombol Selanjutnya untuk Submit",
        "Akan keluar bukti transaksi jika berhasil",
      ],
    },
    {
      name: "I-BANKING / ATM BANK LAIN",
      steps: [
        "Lakukan langkah “Transfer Antar Bank/ke Bank Lain”",
        "Pastikan nama tujuan adalah IPAYMU",
        "Pilih tujuan Bank Syariah Indonesia/ Bank Syariah Mandiri/kode Bank 451",
        "Masukkan kode institusi: 9009042 disertai no VA pembayaran Anda Format 9009042xxxxxxxxxxxx (9009042 = kode institusi, xxxxxxxxxxxx = nomor pembayaran / virtual account) contoh: 9009042123456789123",
        "Pastikan kembali nama tujuan dan jumlah pembayaran sesuai tagihan Anda",
        "Lanjutkan proses sampai transfer berhasil",
      ],
    },
  ],
};

export const MandiriVAFlip: ChannelModel = {
  thumbnail: "mandiri sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "mandiri",
  title: "Mandiri",
  subtitle: "Mandiri Virtual Account",
  image_url: "/assets/mandiri.png",
  hows: [
    {
      name: "ATM Mandiri",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik Mandiri Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikMandiri Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYMandiri APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik Mandiri Bisnis",
      steps: [
        "Silahkan melakukan login di KlikMandiri Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor Mandiri Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke Mandiri Virtual Account”",
        "Pilih rekening sumber dana dan Mandiri Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-Mandiri",
      steps: [
        "Silahkan login pada aplikasi Mandiri Mobile",
        "Pilih “m-Mandiri, lalu masukkan kode akses m-Mandiri",
        "Pilih “m-Transfer”",
        "Lanjut ke Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BSI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BNIVAFlip: ChannelModel = {
  thumbnail: "bni sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bni",
  title: "BNI",
  subtitle: "BNI Virtual Account",
  image_url: "/assets/bni.png",
  hows: [
    {
      name: "ATM BNI",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BNI Pribadi",
      steps: [
        "Silahkan login pada aplikasi BNI Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYMandiri APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BNI Bisnis",
      steps: [
        "Silahkan melakukan login di BNI Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BNI Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BNI Virtual Account”",
        "Pilih rekening sumber dana dan BNI Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BNI",
      steps: [
        "Silahkan login pada aplikasi BNI Mobile",
        "Pilih “m-BNI, lalu masukkan kode akses m-BNI",
        "Pilih “m-Transfer”",
        "Lanjut ke BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BNI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BRIVAFlip: ChannelModel = {
  thumbnail: "bri sprite",
  code: "bri",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  title: "BRI",
  subtitle: "BRI Virtual Account",
  image_url: "/assets/bankbriva.png",
  hows: [
    {
      name: "ATM BRI",
      steps: [
        "Pilih menu utama, pilih Transaksi Lain.",
        "Pilih Pembayaran.",
        "Pilih Lainnya.",
        "Pilih BRIVA.",
        "Masukkan Nomor BRIVA pelanggan dan pilih Benar.",
        "Jumlah pembayaran, nomor BRIVA dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Ya.",
        "Pembayaran telah selesai. Simpan bukti pembayaran Anda.",
      ],
    },
    {
      name: "IB BRI",
      steps: [
        "Masuk pada Internet Banking BRI.",
        "Pilih menu Pembayaran & Pembelian.",
        "Pilih sub menu BRIVA.",
        "Masukkan Nomor BRIVA.",
        "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Kirim.",
        "Masukkan password dan mToken, pilih Kirim.",
        "Pembayaran telah selesai. Untuk mencetak bukti transaksi, pilih Cetak.",
      ],
    },
    {
      name: "BRImo",
      steps: [
        "Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI.",
        "Pilih Pembayaran, lalu pilih BRIVA.",
        "Masukkan nomor BRIVA.",
        "Jumlah pembayaran, nomor pembayaran, dan nama merchant akan muncul pada halaman konfirmasi pembayaran. Jika informasi yang dicantumkan benar, pilih Continue.",
        "Masukkan Mobile Banking BRI PIN, pilih Ok..",
        "Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran.",
      ],
    },
  ],
};

export const CIMBVAFlip: ChannelModel = {
  thumbnail: "bank-transfer sprite",
  code: "cimb",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  title: "CIMB",
  subtitle: "CIMB Virtual Account",
  image_url: "/assets/cimbs2.png",
  hows: [],
};

export const DanamonVAFlip: ChannelModel = {
  thumbnail: "bank-transfer sprite",
  code: "danamon",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  title: "Danamon",
  subtitle: "Danamon Virtual Account",
  image_url: "/assets/danamon.png",
  hows: [],
};

export const PermataVAFlip: ChannelModel = {
  thumbnail: "permata sprite",
  code: "permata",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  title: "Permata",
  subtitle: "Permata Virtual Account",
  image_url: "/assets/permata.png",
  hows: [],
};

export const OvoFlip: ChannelModel = {
  thumbnail: "ovo sprite",
  code: "ovo",
  type: ChannelType.EWALLET,
  payment_type: "wallet_account",
  title: "OVO",
  subtitle: "OVO",
  image_url: "/assets/lgovo2.png",
  hows: [],
};

export const DanaFlip: ChannelModel = {
  thumbnail: "dana sprite",
  code: "dana",
  type: ChannelType.EWALLET,
  payment_type: "wallet_account",
  title: "Dana",
  subtitle: "Dana",
  image_url: "/assets/lgdana2.png",
  hows: [],
};

export const ShopeeFlip: ChannelModel = {
  thumbnail: "shopee sprite",
  code: "shopeepay_app",
  type: ChannelType.EWALLET,
  payment_type: "wallet_account",
  title: "ShopeePay",
  subtitle: "ShopeePay",
  image_url: "/assets/shopeepay.jpg",
  hows: [],
};

export const QrisFlip: ChannelModel = {
  thumbnail: "qris sprite",
  code: "qris",
  type: ChannelType.EWALLET,
  payment_type: "wallet_account",
  title: "QRIS",
  subtitle: "QRIS",
  image_url: "/assets/qris.png",
  hows: [],
};

export const DokuFlip: ChannelModel = {
  thumbnail: "wallet sprite",
  code: "doku",
  type: ChannelType.EWALLET,
  payment_type: "wallet_account",
  title: "Doku",
  subtitle: "Doku",
  image_url: "/assets/doku.png",
  hows: [],
};
